import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, ContainerBox, AlertMsg, Next } from './styles';
import Ponto from '../../assets/images/ponto.png';
import ExitTranslate from '../../Language/Exit';

export default function Channel() {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  
  useEffect(async () => {
    await localStorage.removeItem('pin');
    await sessionStorage.removeItem('link');
    await localStorage.removeItem('senha');
    await localStorage.removeItem('evento');
    await localStorage.removeItem('sala_senha');    

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title,  window.location.href);
    });

  }, []);


  return (
    <Container>
      <ContainerBox>
        <img src={Ponto} alt="phone-icon" />
        <AlertMsg>{ExitTranslate[language].exit.alert}</AlertMsg>
      </ContainerBox>
    </Container>
  )
}
