import { useDispatch, useSelector } from 'react-redux';
import { HeaderStyle, BackSpace, Center } from './styles';
import Language from '../Language';
import LogoutBTN from '../LogoutBTN';
import storage from '../storage';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);

  async function sair() {
    const getStorage = storage;
    dispatch({ type: 'add_app_busca_evento', json: storage.app_busca_evento })
    dispatch({ type: 'add_app_busca_sessao', json: storage.app_busca_sessao })
    dispatch({ type: 'add_pass', loginPass: ''})
    await sessionStorage.setItem('data', '');
    await sessionStorage.removeItem('selectedRoom');
    await sessionStorage.removeItem('selectedChannel');
    await sessionStorage.removeItem('data');
    history.push('./exit');
  }


  async function handleLogout() {
    confirmAlert({
      title: language == 'pt' ? 'Sair' : 'Logout',
      message: language == 'pt' ? 'Deseja sair?' : 'Do you want to exit?',
      buttons: [
        {
          label: language == 'pt' ? 'Sim' : 'Yes',
          onClick: () => sair()
        },
        {
          label: language == 'pt' ? 'Não' : 'No',
          onClick: () => {}
        }
      ]
    });
  }

  return (
    <>
    <HeaderStyle>
      <LogoutBTN text={language === 'pt' ? 'sair': 'logout'} handleLogout={handleLogout} />
      <Center>
        <small style={{color: "white"}}>v 1.1</small>
      </Center>
      <Language language_pt={language === 'pt' ? 'Português': 'Portuguese'} language_en={language === 'pt' ? 'Inglês': 'English'} />
    </HeaderStyle>
    <BackSpace />
    </>
  )
}