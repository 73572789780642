import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, ContainerBox, Item, MicrophoneIcon, TextItem, 
  ChannelName, Title, NextIcon, Loader, SalaNome } from './styles';
import Header from '../../components/Header';
import LanguageJson from '../../Language/Channel';
import Arrow from '../../assets/images/arrow.svg';
import BackBTN from '../../components/BackBTN';
import AppBuscaSessao from '../../components/AppBuscaSessao';
import Microphone from '../../assets/images/microphone.svg'
import loader from '../../assets/images/loader.gif';

export default function Channel() {
  const selectedRoom = useSelector((state) => state.selectedRoom);
  const history = useHistory();
  const dispatch = useDispatch();
  const app_busca_evento = useSelector((state) => state.app_busca_evento);
  const app_busca_sessao = useSelector((state) => state.app_busca_sessao);
  const language = useSelector((state) => state.language);
  const [channelList, setChannelList] = useState([]);
  const [showLoader, setShowLoader] = useState(<img src={loader} alt="loader" />);
  const [update, setUpdate] = useState(false);
  const [voltar, setVoltar] = useState(true);
  const [nomesala, setNomesala] = useState('');

  useEffect(() => {
    (async () => {
      sessionStorage.removeItem('selectedChannel');
      let getRoom = await sessionStorage.getItem('selectedRoom');
      getRoom = await JSON.parse(getRoom);

      setNomesala(getRoom.sala_nome);

      const response = await AppBuscaSessao(getRoom.sessao_id);
      let canaisFinais = [];
      console.log(response);
      for(var aux = 0; aux < response.canais.length; aux++) {
        if (response.canais[aux].canal_estado)
          canaisFinais.push(response.canais[aux]);
      }
      
      if (response.sessao.sessao_estado !== 3) {
        history.push('./rooms');
      }

      await setChannelList(canaisFinais);
      await dispatch({ type: 'add_app_busca_sessao', json: response });
      setShowLoader(null);

      let sala_senha = await localStorage.getItem('sala_senha');
      if (sala_senha == 's') {
        setVoltar(false);
      }

    })()

  }, []);



  useEffect(() => {

    (async () => {
      if (update) {

        let getSelectedRoom = await sessionStorage.getItem('selectedRoom');
        getSelectedRoom = await JSON.parse(getSelectedRoom);

        const response = await AppBuscaSessao(getSelectedRoom.sessao_id);
        let canaisFinais = [];
        for(var aux = 0; aux < response.canais.length; aux++) {
          if (response.canais[aux].canal_estado)
            canaisFinais.push(response.canais[aux]);
        }

        await setChannelList(canaisFinais);

        const [getSessaoId] = await app_busca_evento.evento.sessao.filter((item) => item.sessao_id === getSelectedRoom.sessao_id);
    
        await dispatch({ type: 'select_room', item: getSessaoId })
        await sessionStorage.setItem('selectedRoom', JSON.stringify(getSessaoId));
        
        if (getSelectedRoom.sessao_estado !== 3) {
          history.push('./rooms');
        }
      }

    })();

    setUpdate(true);

  }, [app_busca_evento, app_busca_sessao])

  async function handleBackPage() {
    await sessionStorage.removeItem('selectedRoom');
    history.push('/rooms');
  }

  async function handleChannelClick(item) {
    await dispatch({ type: 'select_channel', item: item })
    await sessionStorage.setItem('selectedChannel', JSON.stringify(item));
    return history.push('/wifi')
  }

  return (
    <Container>
      <Header />
      { voltar === true ? <BackBTN handleBackPage={handleBackPage} language={language} /> : '' }
      
      <ContainerBox>
        <SalaNome>{ language === 'pt' ? 'Sala: ' : 'Room: ' } {nomesala} 
        <Title>{LanguageJson[language].Channel.title}</Title>
        </SalaNome>
        <Loader>{showLoader}</Loader>
        {channelList ? channelList.map((item) => (
          <Item key={item.canal_id.toString()} onClick={() => handleChannelClick(item)}>
            <MicrophoneIcon><img src={Microphone} /></MicrophoneIcon>
            <TextItem>
              <ChannelName>{language === 'pt' ? item.canal_nome : item.canal_ingles || ''}</ChannelName>
            </TextItem>
            <NextIcon>
              <img src={Arrow} alt="next" />
            </NextIcon>
          </Item>
        )) : null}
      </ContainerBox>
    </Container>
  )
}