import styled from 'styled-components';

export const HeaderStyle = styled.div`
  display: flex;
  position: absolute;
  background-color: #010f5c;
  top: 0;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 0 30px;

  @media (max-width: 700px) {
  
  }

  @media (max-width: 620px) {
    height: 60px;
  }
`;

export const BackSpace = styled.div`
  height: 60px;
  width: 100%;
`;

export const Center = styled.div`
  display: block; 
  margin-left: 3rem; 
`;