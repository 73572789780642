import styled from 'styled-components';

import bgImage from '../../assets/images/bg.png';

//

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  padding: 150px;
  flex-direction: column;
  @keyframes anima {
    from {
      transform: translateX(-26px);
      opacity: 0;
    }; 
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 1350px) {
    padding: 100px;
  }

  @media (max-width: 1000px) {
    padding: 100px 80px;
  }

  @media (max-width: 800px) {
    padding: 100px 40px;
  }

  @media (max-width: 600px) {
    padding: 100px 10px;
  }
`;

export const ContainerBG = styled.div`
  width: 100%;
  height: 100vh;
  background: #001279a1;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const PontoLogo = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;


  img {
    width: 220px;
  }

  @media (max-height: 500px) {
    height: 80px;

    img {
      width: 150px;
    }
  }

  @media (max-width: 520px) {   
    height: 90px;

    img {
      width: 150px;
    }
  }

  
`;

export const LoginBox = styled.div`
  width: 96vw;
  max-width: 500px;
  height: 150vw;
  max-height: 400px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 30px 0;
    text-align: center;
    color: #055ff080;
    font-size: 20px;
    font-style: italic;
  }

  @media (max-height: 500px) {
    height: 350px;
    font-size: 12px;
  }

  @media (max-width: 520px) {   
    height: 350px;
    font-size: 12px;
  }


`;

export const LoginForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  button {
    border: none;
    width: 80%;
    height: 50px;
    background-color: ${(props) => props.isLoading ? '#fff' : '#055ff0'};
    color: #fff;
    font-weight: bolder;
    letter-spacing: 1px;
    font-size: 16px;
    font-variant: small-caps;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    margin: 20px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  small {
    position: relative;
    top: -10px;
  }

  @media (max-height: 500px) {
    button {
      height: 45px;
    }
  }

  @media (max-width: 520px) {
    button {
      width: 94%;
    }

    small {
      position: relative;
      top: -10px;
      left: -30px;
    }
  }

  

`;


export const LoginPassword = styled.div.attrs({
  placeholder: 'aaaaaaaaa',
})`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:  6px;
    overflow: hidden;
    margin: 20px 0;
    height: 50px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
    background-color: #dedede90;
    border: none;
    }


    @media (max-height: 500px) {
      margin: 8px 0;
    }

    @media (max-width: 520px) {
      width: 94%;

      input {
        padding: 10px 0;
      }

    }
  
`;

export const Lock = styled.div`
  height: 100%;
  width: 80px;
  background-color: #dedede90;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 520px) {
    width: 50px;
  }

`;

export const Loading = styled.img`
  width: 300px;
  height: 200px;
`;

export const Alert = styled.small`
  color: #f00;
  align-self: flex-start;
  margin-left: 12%;
`;

export const ImageStyle = styled.div`
  img {
    width: 160px;
  }

  @media (max-width: 600px) {
    img {
      width: 80px;
    }
  
  }
`;

export const ContainerBox = styled.div`
    width: 96%;
    min-height: 50vh;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  

    @media (max-width: 600px) {
      div h3 {
        font-size: 14px;
      }
    }
`;

export const AlertMsg = styled.div`
  margin : 30px 0 80px 0; 
  text-align: center;
  letter-spacing: 2px;
  line-spacing: 5px;
`;