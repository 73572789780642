import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  padding: 150px;
  flex-direction: column;
  @keyframes anima {
    from {
      transform: translateX(-26px);
      opacity: 0;
    }; 
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: 1350px) {
    padding: 100px;
  }

  @media (max-width: 1000px) {
    padding: 100px 80px;
  }

  @media (max-width: 800px) {
    padding: 100px 40px;
  }

  @media (max-width: 600px) {
    padding: 100px 10px;
  }
`;

export const ContainerBox = styled.div`
    width: 100%;
    min-height: 50vh;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SalaNome = styled.div`
  font-size: 17px;
  align-self: flex-start;
  margin: 0 0 0 0;
  letter-spacing: 2px;

  @media (max-width: 1350px) {
    margin:  0 0 0 0;
  }

  @media (max-width: 1000px) {
    margin:  0 0 0 0;
  }

  @media (max-width: 800px) {
    margin:  0 0 0 0;
  }

  @media (max-width: 600px) {
    margin:  0 0 0 0;
    font-size: 17px;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  font-weight: bolder;
  align-self: flex-start;
  margin: 20px 0 0 0;
  letter-spacing: 2px;

  @media (max-width: 1350px) {
    margin: 20px 0 0 0;
  }

  @media (max-width: 1000px) {
    margin: 20px 0 0 0;
  }

  @media (max-width: 800px) {
    margin: 20px 0 0 0;
  }

  @media (max-width: 600px) {
    margin: 20px 0 0 0;
    font-size: 20px;
  }
`;

export const Item = styled.div`
  animation-name: anima;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  position: relative;
  width: 96%;
  max-width: 600px;
  height: 80px;
  border: 2px solid #c1c1c180;
  border-radius: 6px;
  margin: 14px 0; 
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease-out 0s;
  overflow: hidden;

  :hover {
    box-shadow: 0px 0px 14px 5px #00000030;
    max-width: 620px;
  }
  
  @media (max-width: 600px) {
    height: 70px;
    margin: 8px 0; 
  }
  
`;

export const MicrophoneIcon = styled.div`
  width: 70px;
  margin-right: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000010;

  img {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 600px) {
    width: 60px;
    margin-right: 30px;

    img {
      width: 26px;
      height: 26px;
    }
  }
`;

export const TextItem = styled.div``;

export const ChannelName = styled.div`
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 1px;
  color: #010f5c;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const NextIcon = styled.div`
  width: 24px;
  height: 26px;
  transform: translateY(2px);
  position: absolute;
  right: 10px;
`;

export const IsRestrict = styled.div`
  background-color: #a21111;
  color: #fff;
  padding: 2px 10px;
  border-radius: 4px;
  font-size: 13px;
  margin-left: 50px;
  font-variant: small-caps;
  letter-spacing: 1px;
`;

export const Loader = styled.div`
  img {
    width: 80px;
  }

  @media (max-width: 600px) {
    img {
      width: 40px;
    }
  
  }
`;