module.exports = {
  pt: {
    login: {
      placeholder: 'Informe o PIN',
      loginButton: 'entrar',
      notfound: 'Nenhum evento encontrado, verifique o PIN e tente novamente!',
      pinerror: 'Este PIN está sendo utilizado. Por favor, tente novamente mais tarde ou contacte a organização do evento.',
      error: 'Oops! Os parâmetros informados são inválidos. Por favor, volte uma tela e tente novamente ou contacte a organização do evento.',
      pt: 'Português',
      en: 'Inglês',
    }
  },
  en: {
    login: {
      placeholder: 'Enter the PIN',
      loginButton: 'Sign in',
      notfound: 'No events were found, check the PIN and try again!',
      pinerror: 'This PIN is being used. Please try again later or contact the event organizer.',
      error: 'Oops! The parameters entered are invalid. Please go back one screen and try again or contact the event organization.',
      pt: 'Portuguese',
      en: 'English',
    }
  }
}
