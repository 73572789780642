import { useState, Text } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, ImageStyle, ContainerBox, AlertMsg } from './styles';
import AppBuscaEvento from '../../components/AppBuscaEvento';
import { useEffect } from 'react';
import AppLink from '../../components/AppLink';
import loader from '../../assets/images/loader.gif';
import errorImage from '../../assets/images/alert.svg';
import LoginTranslate from '../../Language/Login';

export default function Login({ location, props_app_busca_evento }) {
  const language = useSelector((state) => state.language)
  const [linkError, setLinkError] = useState(false);
  const [showLoader, setShowLoader] = useState(<img src={loader} alt="loader" />);
  const [showImageError, setShowImageError] = useState(<img src={errorImage} alt="error" />);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(async () => {
    let idioma = 'pt';
    var link = window.location.href;
    const link_temp = await sessionStorage.getItem('link');
    if (link_temp != null) {
      link = link_temp;
    } else {
      
      if (link.includes('&')) {
        if (link.split('&')[1].split('=')[1] == 'en') {
          idioma = 'en';
        }
        link = link.split('&')[0];
      }
    }  

    await localStorage.removeItem('pin');
    await localStorage.removeItem('data');
    await localStorage.removeItem('senha');
    await localStorage.removeItem('evento');
    await localStorage.removeItem('sala_senha');
    await sessionStorage.removeItem('link');

    const response = await AppLink(link);
    const AppBuscaEventoJSON = await AppBuscaEvento(response.senha);
    await sessionStorage.setItem('pin', response.senha);
    if (response.estado === 'sucesso') {
      await sessionStorage.setItem('link', link);
    }
    
    console.log(idioma);
    localStorage.setItem('language', idioma);
    dispatch({type: 'change_language', language: idioma});

    handleResponse(AppBuscaEventoJSON, response);
  }, []);

  async function handleResponse(response, value) {
    if (response.estado === 'sucesso') {
      dispatch({
        type: 'add_pass',
        loginPass: value,
      });    
      dispatch({
        type: 'add_app_busca_evento',
        json: response,
      });
      dispatch({
        type: 'change_auth',
        current: true,
      });
      await localStorage.setItem('pin', value.senha);
      await localStorage.setItem('evento', response.evento.evento_titulo);
      console.log(value);
      console.log(value.idsala);
      if (value.idsala == undefined) {
        return history.push('/rooms');
      } else {
        for (var aux = 0; aux < response.evento.sessao.length; aux++) {
          if (response.evento.sessao[aux].sala_nome == value.nome_sala) {
            await dispatch({ type: 'select_channel', item: response.evento.sessao[aux] });
            await sessionStorage.setItem('selectedRoom', JSON.stringify(response.evento.sessao[aux]));
            break;
          }
        }
        await localStorage.setItem('sala_senha', 's');
        history.push('./channel', { item: response.evento.sessao[aux], })
      } 
    } else {
      console.log(response);
      setLinkError(true);
    }
  }

  return (
    <Container>
        {linkError == false ? 
          <ImageStyle>{showLoader}</ImageStyle>
          :
          <ContainerBox>
            <ImageStyle>{showImageError}</ImageStyle>
            <AlertMsg>{LoginTranslate[language].login.error}</AlertMsg>
          </ContainerBox>
        }
    </Container>   
  )
}
