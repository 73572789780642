import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Container, ContainerBox, Item, LockIcon, TextItem, RoomName, Title, NextIcon, IsRestrict, Estado, NotFound } from './styles';
import Header from '../../components/Header';
import LanguageJson from '../../Language/Menu';
import Lock from '../../assets/images/lock2.svg';
import Unlock from '../../assets/images/unlock.svg';
import Arrow from '../../assets/images/arrow.svg';
import BackBTN from '../../components/BackBTN';
import estado from '../../Language/estado';
import Alert from '../../assets/images/alert.svg';

export default function Menu() {
  const app_busca_evento = useSelector((state) => state.app_busca_evento);
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const [menuList, setMenuList] = useState([]);
  async function handleBackPage() {
    history.push('./home');
  }

  useEffect(() => {
    (async () => {
      sessionStorage.setItem('restrict', false);
      let getData = await sessionStorage.getItem('data');
      getData = JSON.parse(getData);
      await setMenuList(getData.app_busca_evento.evento.sessao)
    })()

  }, []);

  useEffect(() => {
    if (app_busca_evento.evento) {
      setMenuList(app_busca_evento.evento.sessao);
    }
  }, [app_busca_evento])

  async function handleRoom(item) {
    if (item.sessao_estado === 3) {
      if (item.sessao_restrita) {
        await dispatch({ type: 'select_channel', item: item });
        await sessionStorage.setItem('selectedRoom', JSON.stringify(item));
        return (
          history.push('./restrict-channel')
        )
      }

      await dispatch({ type: 'select_channel', item: item });
      await sessionStorage.setItem('selectedRoom', JSON.stringify(item));
      history.push('./channel', {
        item: item,
      })
    }
  }



  return (
    <Container>
      <Header />
      <ContainerBox>
        <Title>{menuList.length > 0 ? LanguageJson[language].Menu.title : ''}</Title>
        {menuList.length > 0 ? menuList.map((item) => (
          <Item onClick={() => handleRoom(item)} key={item.sessao_id.toString()}>
            <LockIcon>{
              item.sessao_restrita ? (
                <img src={Lock} alt="lock-icon" />
              ) : (
                <img src={Unlock} alt="unlock-icon" />
              )
            }</LockIcon>
            <TextItem>
              <RoomName>{item.sala_nome}</RoomName>
            </TextItem>
            {item.sessao_restrita ? (
              <IsRestrict>{LanguageJson[language].Menu.restrict}</IsRestrict>
            ) : (
              null
            )}
            <Estado>{estado[language][item.sessao_estado]}</Estado>
            {item.sessao_estado == 3 ? (
              <NextIcon>
                <img src={Arrow} alt="next" />
              </NextIcon>
            ) : (null)}
          </Item>
        )) : <NotFound><img src={Alert} alt="alert" />{LanguageJson[language].Menu.notFound}</NotFound>

        }
      </ContainerBox>
    </Container>
  )
}